import { Link } from "react-router-dom";
import { StatementType } from "./utils";
import { formatCurrency } from "../../utils/currency";
import { CButton } from "@coreui/react";
import { formatUTCDate } from "./../../utils/string";
import Badge from "./../../components/Badge";

export const COLUMNS = [
  {
    key: "invoiceNumber",
    title: "Statement Number",
    render: (invoiceNumber: string) => <span>{invoiceNumber}</span>,
  },
  {
    key: "invoiceDate",
    title: "Statement Date",
    render: (invoiceDate: string) => <span>{formatUTCDate(invoiceDate)}</span>,
  },
  {
    key: "billingPeriod",
    title: "Billing Period",
    render: (_: any, record: StatementType) => {
      return (
        <span>
          {formatUTCDate(record.billingStartDate)}
          {" - "}
          {formatUTCDate(record.billingEndDate)}
        </span>
      );
    },
  },
  {
    key: "paymentDueDate",
    title: "Due Date",
    render: (paymentDueDate: string) => (
      <span>{formatUTCDate(paymentDueDate)}</span>
    ),
  },
  {
    key: "periodChargeAccrual",
    title: "Period Charges",
    render: (periodChargeAccrual: string) => (
      <span>{formatCurrency(periodChargeAccrual)}</span>
    ),
  },
  {
    key: "unpaidInvoiceBalance",
    title: "Period Due Balance",
    render: (unpaidInvoiceBalance: string) => (
      <span>{formatCurrency(Number(unpaidInvoiceBalance) || 0)}</span>
    ),
  },
  {
    key: "paymentStatus",
    title: "Payment Status",
    render: (paymentStatus: string) => {
      // console.log('Statement paymentStatus:', paymentStatus);
      return <Badge status={paymentStatus} />;
    },
  },
  {
    key: "invoiceLink",
    title: "King Energy Statement",
    render: (invoiceLink: any) => (
      <Link to="/statement" state={{ link: invoiceLink }}>
        <CButton style={{ whiteSpace: "nowrap" }}>View</CButton>
      </Link>
    ),
  },
];
