import classnames from "classnames";

interface PropTypes {
  status: string;
  className?: string;
}

export default function Badge(props: PropTypes) {
  const { status, className, ...rest } = props;

  const defaultStatusColorMap = {
    secondary: ["VOID"],
    danger: [
      "FAILED",
      "PAST_DUE",
      "CHARGEBACK",
      "CANCELLED",
      "DISABLED",
      "BALANCE_CARRY_FORWARD",
      "LATE",
    ],
    warning: ["PENDING", "PROCESSING", "UNPAID"],
    success: [
      "ACCEPTED",
      "SUCCEEDED",
      "PAID",
      "PAID_EXTERNALLY",
      "CONSUMED",
      "ENABLED",
      "CURRENT",
      "UNPAID",
      "CREDIT",
    ],
  };

  const getStatusClass = (status: string) => {
    if (defaultStatusColorMap.secondary.includes(status))
      return "bg-secondary text-dark";
    if (defaultStatusColorMap.danger.includes(status))
      return "bg-danger text-white";
    if (defaultStatusColorMap.warning.includes(status))
      return "bg-warning text-white";
    if (defaultStatusColorMap.success.includes(status))
      return "bg-success text-white";
    return "bg-secondary text-dark";
  };

  if (!status) {
    return null;
  }

  return (
    <span
      className={classnames("badge", getStatusClass(status), className)}
      style={{ paddingBottom: 3 }}
      {...rest}
    >
      {status.replace(/_/g, " ")}
    </span>
  );
}
