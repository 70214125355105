import React, { useEffect, useCallback } from "react";
import StatementSummaryComponent from "./components/StatementSummaryComponent";
import PaymentHistoryComponent from "./components/PaymentHistoryComponent";
import { AutoPayComponent } from "./components/AutoPayComponent";
import { PaperlessComponent } from "./components/PaperlessComponent";
import { getPaymentMethods } from "../PaymentMethods/PaymentMethods.slice";
import { CAlert, CLink, CButton, CFade } from "@coreui/react";
import { useAuth0 } from "@auth0/auth0-react";
import { trackEvent } from "./../../utils/analytics";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import AccountPastDueAlert from "./components/AccountPastDueAlert";

export const Dashboard: React.FC = () => {
  const { loading, error, data, activeTenantAccountIndex } = useAppSelector(
    (state) => state.accountSummary
  );
  const payment = useAppSelector((state) => state.payment);
  const { logout, isLoading } = useAuth0();
  const dispatch = useAppDispatch();
  const isImpersonating = window.location !== window.parent.location; // App is loaded in an iframe

  const getDashboardSummaryCB = useCallback(() => {
    dispatch(getPaymentMethods()).catch((e: string) => {
      throw new Error(e);
    });
  }, [dispatch]);

  useEffect(() => {
    getDashboardSummaryCB();
    trackEvent({ action: "View > Dashboard" });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (error && !loading && !isLoading) {
    return (
      <CAlert style={{ maxWidth: 500 }}>
        <h2>Error accessing your account</h2>
        <p>Please try again later, or contact us for further assistance.</p>
        <p>
          Phone: <CLink href="tel://8007811765">(800) 781-1765</CLink>
          <br />
          Email:{" "}
          <CLink href="mailto:support@kingenergy.com">
            support@kingenergy.com
          </CLink>
        </p>
        <p>
          <CButton
            color="secondary"
            onClick={() => {
              logout({ logoutParams: { returnTo: window.location.origin } });
            }}
          >
            Log Out
          </CButton>
        </p>
      </CAlert>
    );
  }

  if (loading || !data.length || (isLoading && !isImpersonating)) {
    return <div>Loading...</div>;
  }

  const accountSummaryData = data?.[activeTenantAccountIndex];
  const payments = accountSummaryData?.payments;
  const isAutopayEnabled = accountSummaryData?.autopay?.enabled

  return (
    <CFade>
      <div className="row">
        <AccountPastDueAlert summary={accountSummaryData} />
        <div className="col-sm-6">
          <StatementSummaryComponent
            isAutopayEnabled={Boolean(isAutopayEnabled)}
            summary={accountSummaryData}
            payment={payment}
          />
        </div>
        <div className="col-sm-6">
          <PaymentHistoryComponent activities={payments} />
        </div>
      </div>
      <div className="row">
        <div className="col-sm-12 col-xl-6">
          <AutoPayComponent />
        </div>
        <div className="col-sm-12 col-xl-6">
          <PaperlessComponent />
        </div>
      </div>
    </CFade>
  );
};

export default Dashboard;
