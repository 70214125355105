import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { getStatementHistoryApi } from '../../apis/apis'
import { RootState } from "../../store/store";

const SLICE_NAME = "STATEMENT_SLICE";

export const getStatements = createAsyncThunk(
  `${SLICE_NAME}/getStatements`,
  async (_, { rejectWithValue, getState }) => {
    try {
      const state = getState() as RootState;
      if (state.accountSummary.activeTenantAccountId) {
        const response = await getStatementHistoryApi(
          state.accountSummary.activeTenantAccountId
        );
        return response as Statement[];
      } else {
        throw new Error("activeTenantAccountId not set");
      }
    } catch (err) {
      return rejectWithValue({
        err,
      });
    }
  }
);

export interface StatementHistory {
  loading: boolean;
  error: boolean;
  data: Statement[] | [];
}

export interface Statement {
  id: string;
  invoiceNumber: string;
  tenantAccountId: string;
  invoiceDate: Date;
  paymentDueDate: Date;
  billingStartDate: Date;
  billingEndDate: Date;
  invoiceAmount: number;
  remainingBalance: number;
  invoiceStatus: string;
  invoiceLink: string;
  invoiceExternalLink?: string;
  paymentStatus: string;
  dueBalanceTenantToCorp: number;
  pastDueBalance: number;
  periodChargeAccrual: number;
}

const initialState: StatementHistory = {
  loading: false,
  error: false,
  data: [],
};

const slice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getStatements.pending, (state: StatementHistory) => {
      state.loading = true;
      state.error = false;
    });
    builder.addCase(
      getStatements.fulfilled,
      (state: StatementHistory, { payload }: any) => {
        state.loading = false;
        state.error = false;
        state.data = payload;
      }
    );
    builder.addCase(
      getStatements.rejected,
      (state: StatementHistory, { payload }: any) => {
        state.loading = false;
        state.error = payload;
      }
    );
  },
});

export const { reducer: statementReducer } = slice
