import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getAccountSummaryApi } from "./apis/apis";
import { trackEvent } from "./utils/analytics";

const SLICE_NAME = "DASHBOARD_SLICE";

const transformResponse = (response: AccountSummaryData[]) =>
  response.map((as) => {
    return {
      ...as,
      serviceAddressConcat: `${as.serviceAddress?.address1}${
        as.serviceAddress?.address2 ? `, ${as.serviceAddress?.address2}` : ""
      },
        ${as.serviceAddress?.city}, ${as.serviceAddress?.state} ${
        as.serviceAddress?.zip
      }`,
    };
  });

export const getAccountSummary = createAsyncThunk(
  `${SLICE_NAME}/getAccountSummary`,
  async (_, { rejectWithValue }) => {
    try {
      trackEvent({ action: "API > Account Summary" });
      const response = await getAccountSummaryApi();
      return transformResponse(response);
    } catch (err) {
      return rejectWithValue({
        err,
      });
    }
  }
);

export const refreshAccountSummary = createAsyncThunk(
  `${SLICE_NAME}/refreshAccountSummary`,
  async (_, { rejectWithValue }) => {
    try {
      const response = await getAccountSummaryApi();
      return transformResponse(response);
    } catch (err) {
      return rejectWithValue({
        err,
      });
    }
  }
);

export const setActiveTenantAccountId = createAsyncThunk(
  `${SLICE_NAME}/setActiveTenantAccountId`,
  (tenantAccountId: string) => tenantAccountId
);

export interface AccountSummary {
  loading: boolean;
  error: boolean;
  data: AccountSummaryData[] | [];
  activeTenantAccountId: string;
  activeTenantAccountIndex: number;
}

export interface AccountSummaryData {
  id: string;
  accountName: string;
  accountNumber: string;
  serviceAddress: ServiceAddress;
  invoice: Invoice;
  payments: Payment[];
  lastSuccessfulPayment: Payment | null;
  sharedAccount: boolean;
  autopay: Autopay;
  paperless: boolean;
  serviceAddressConcat: string;
  paymentStatus: string | null;
  pastDueBalance: number;
}

export interface Autopay {
  enabled: boolean;
  owner: boolean;
  nextCycle: NextCycle;
  paymentMethodId: null | string;
}

export interface NextCycle {
  debitAmount: number;
  runDate: Date;
}

export interface Invoice {
  id: string;
  invoiceNumber: string;
  invoiceDate: Date;
  paymentDueDate: Date;
  billingStartDate: Date;
  billingEndDate: Date;
  invoiceAmount: number;
  remainingBalance: number;
  invoiceStatus: string;
  invoiceLink: string;
  invoiceExternalLink?: string;
  pastDueBalance: number;
}

export interface Payment {
  id: string;
  tokenProvider: string;
  status: string;
  amount: number;
  paymentDate: Date;
  postingDate: Date;
  invoiceId: string;
  paymentMethodId: string;
  paymentNumber?: string;
}

export interface ServiceAddress {
  address1: string;
  address2: null | string;
  city: string;
  state: string;
  zip: string;
}

const initialState: AccountSummary = {
  loading: false,
  error: false,
  data: [],
  activeTenantAccountId: "",
  activeTenantAccountIndex: 0,
};

const slice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAccountSummary.pending, (state: AccountSummary) => {
      state.loading = true;
      state.error = false;
    });
    builder.addCase(
      getAccountSummary.fulfilled,
      (state: AccountSummary, { payload }) => {
        state.loading = false;
        state.error = false;
        state.data = payload;
        state.activeTenantAccountId = payload?.[0]?.id;
        state.activeTenantAccountIndex = state.data.findIndex(
          (ta) => ta.id === (state.activeTenantAccountId ?? payload?.[0]?.id)
        );
      }
    );
    builder.addCase(
      getAccountSummary.rejected,
      (state: AccountSummary, { payload }: any) => {
        state.loading = false;
        state.error = payload;
      }
    );

    builder.addCase(
      refreshAccountSummary.fulfilled,
      (state: AccountSummary, { payload }: any) => {
        state.loading = false;
        state.error = false;
        state.data = payload;
      }
    );

    builder.addCase(
      setActiveTenantAccountId.fulfilled,
      (state: AccountSummary, { payload }: any) => {
        state.loading = false;
        state.error = false;
        state.activeTenantAccountId = payload;
        state.activeTenantAccountIndex = state.data.findIndex(
          (ta) => ta.id === payload
        );
      }
    );
  },
});

export const { reducer: accountSummaryReducer } = slice;
