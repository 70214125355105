import { sygnet } from './sygnet'
import { logo } from './logo'
import { logoNegative } from './logo-negative'

import {
  cisFilePdf,
  cilLeaf,
  cilApplicationsSettings,
  cilSpeedometer,
  cilSun,
  cilMoon,
  cilBank,
  cilDescription,
  cilDollar,
  cilTask,
  cilLoop,
  cilAccountLogout,
  cilList,
  cilInputPower,
  cisStar,
  cisCheckCircle,
  cisXCircle,
  cibAmericanExpress,
  cibVisa,
  cilUser,
  cisUser,
  cilBuildingBusiness,
  cilSettings,
  cilFolderOpen,
  cilWarning,
} from "@coreui/icons-pro";

export const icons = Object.assign(
  {},
  {
    sygnet,
    logo,
    logoNegative,
  },
  {
    cilApplicationsSettings,
    cilSpeedometer,
    cilSun,
    cilMoon,
    cilBank,
    cilDescription,
    cilDollar,
    cilTask,
    cilLoop,
    cilLeaf,
    cilAccountLogout,
    cilList,
    cisFilePdf,
    cilInputPower,
    cisStar,
    cisCheckCircle,
    cisXCircle,
    cibAmericanExpress,
    cibVisa,
    cilUser,
    cisUser,
    cilBuildingBusiness,
    cilSettings,
    cilFolderOpen,
    cilWarning,
  }
);
