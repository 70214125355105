import React from "react";
import { useAppSelector } from "../../../store/hooks";
import { CButton, CAlert } from "@coreui/react";
import CIcon from "@coreui/icons-react";
import { formatCurrency } from "../../../utils/currency";
import { useNavigate } from "react-router-dom";
import { AccountSummaryData } from "../../../AccountSummary.slice";

interface PropsInterface {
  summary: AccountSummaryData;
}

const AccountPastDueAlert: React.FC<PropsInterface> = (props) => {
  const { summary } = props;
  const { profile } = useAppSelector((state: { user: any }) => state.user);
  const navigate = useNavigate();

  if (summary.paymentStatus !== "PAST_DUE") {
    return null;
  }

  return (
    <div className="col-sm-12">
      <div className="card">
        <CAlert color="danger" style={{ margin: "0" }}>
          <div style={{ margin: "0 0 8px 0" }}>
            <CIcon
              name="cil-warning"
              size="xl"
              style={{ margin: "-10px 12px 0 2px" }}
            />
            <span
              style={{
                fontSize: "1.5em",
                fontWeight: 600,
              }}
            >
              Account Past Due
            </span>
          </div>
          <div style={{ margin: "0 0 10px 38px" }}>
            <div
              style={{ fontSize: "1.3em", fontWeight: 400, marginBottom: 10 }}
            >
              {profile.firstName ? `${profile.firstName}, we` : "We"} notice
              that your account has a past due balance of{" "}
              {formatCurrency(summary.pastDueBalance)}.
            </div>
            <div style={{ fontSize: "1em", fontWeight: 400 }}>
              To ensure uninterrupted electric service and avoid additional late
              fees, please submit your payment as soon as possible. If you need
              assistance or would like to discuss payment options, please give
              us a call at <a href="tel:8007811765">(800) 781-1765</a> or email
              us as at{" "}
              <a href="mailto:support@kingenergy.com">support@kingenergy.com</a>
              .
              <div style={{ margin: "12px 0 0 0" }}>
                <CButton
                  color="primary"
                  onClick={() => {
                    navigate("/make-payment");
                  }}
                >
                  Pay Now
                </CButton>
              </div>
            </div>
          </div>
        </CAlert>
      </div>
    </div>
  );
};

export default AccountPastDueAlert;
